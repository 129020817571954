<template>
  <section class="statistic">
    <StatisticWrapper
      :dataType="dataType"
      :dateRange="dateRange"
      :formattedData="formattedData"
      :chartLabels="chartLabels"
      @dateChange="handleDateChange"
      :hkCode="dataType.name"
      :isTooltipCustom="isSleepAnalysis"
    />
    <div>
      <AltTable
        :columns="tableColumns"
        pagination
        :paginationOptions="paginationOptions"
        @onPagination="handlePagination"
      >
        <template #t-row>
          <template v-if="!isSleepAnalysis">
            <tr v-for="(item, index) in paginatedItems" :key="index">
              <td>{{ formatDateTime(item.startDate) }}</td>
              <td>{{ formatValue(item.value, item.unit) }}</td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="(item, index) in paginatedItems" :key="index">
              <td>{{ formatDateTime(item.startDate) }} - {{ formatDateTime(item.endDate) }}</td>
              <td>{{ formatValue(item.value, item.unit) }}</td>
            </tr>
          </template>
        </template>
      </AltTable>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, onMounted, inject } from 'vue';
import StatisticWrapper from '@/healthKit/organisms/StatisticWrapper.vue';
import AltTable from '@/components/tables/altTable.vue';
import { getHealthKitDataByCategory } from '../services/healthCategories.services';
import { toast } from 'vue3-toastify';
import { formatChartData, formatLineDataSleepAnalysis } from '@/healthKit/utils/graphDataUtils';
import { HK_CATEGORIES_SLEEP_ANALYSIS, TYPE_COLLECTION } from '../utils/HealthKit';

const props = defineProps({
  dataType: {
    default: () => ({
      key: '',
      name: '',
      color: '',
      typeChart: '',
    }),
    required: true,
  },
  rangeDate: {
    required: true,
  },
});

const dateRange = ref(props.rangeDate);

const currentPage = ref(1);
const itemsPerPage = ref(10);
const healthKitData = ref([]);
const hkCode = ref(props.dataType.key);
const collectedFormData = inject('collectedFormData');

const graphType = computed(() => props.dataType.typeChart);

const chartLabels = computed(() => {
  return graphType.value === 'SCATTER' ? ['X Axis', 'Y Axis'] : [];
});

const isSleepAnalysis = computed(() => HK_CATEGORIES_SLEEP_ANALYSIS.includes(hkCode.value));

const formattedData = computed(() => {
  if (!healthKitData.value.length) return [];

  const sortedData = [...healthKitData.value].sort(
    (a, b) => new Date(a.startDate) - new Date(b.startDate)
  );

  if (isSleepAnalysis.value) return formatLineDataSleepAnalysis(hkCode.value, sortedData) ?? [];

  return formatChartData(graphType.value, hkCode.value, sortedData) ?? [];
});

const paginationOptions = computed(() => ({
  limit: [10, 20],
  total: healthKitData.value.length,
  currentPage: currentPage.value,
}));

const paginatedItems = computed(() => {
  if (!healthKitData.value.length) return [];
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = currentPage.value * itemsPerPage.value;
  return healthKitData.value.slice(start, end);
});

const tableColumns = ref([{ header: 'Date' }, { header: 'Value' }]);

function formatDateUTC(date) {
  const z = (n) => n.toString().padStart(2, '0');
  const month = z(date.getUTCMonth() + 1);
  const day = z(date.getUTCDate());
  const year = date.getUTCFullYear();
  let hour = date.getUTCHours();

  const minute = z(date.getUTCMinutes());
  const second = z(date.getUTCSeconds());
  const ampm = hour >= 12 ? 'PM' : 'AM';

  hour = hour % 12;
  hour = hour ? z(hour) : '12';

  return `${month}/${day}/${year}, ${hour}:${minute}:${second} ${ampm}`;
}

function formatDateTime(dateString) {
  const date = new Date(dateString);
  return formatDateUTC(date);
}

function formatValue(value, unit) {
  return `${parseFloat(value).toFixed(2)} ${unit}`;
}

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
}

function handlePagination(pagination) {
  currentPage.value = pagination.currentPage;
  itemsPerPage.value = pagination.limit;
  const totalPages = Math.ceil(healthKitData.value.length / itemsPerPage.value);
  if (currentPage.value > totalPages) {
    currentPage.value = totalPages;
  }
}

function handleDateChange(value) {
  dateRange.value.startDate = new Date(value.startDate);
  dateRange.value.endDate = new Date(value.endDate);
  updateHealthKitData();
}

async function updateHealthKitData() {
  try {
    const data = await getHealthKitDataByCategory({
      userId: collectedFormData.userId,
      studyId: collectedFormData.studyId,
      codeFilter: hkCode.value,
      startDate: dateRange.value.startDate,
      endDate: dateRange.value.endDate,
      collectionType: HK_CATEGORIES_SLEEP_ANALYSIS.includes(hkCode.value)
        ? TYPE_COLLECTION.SLEEP_ANALYSIS
        : TYPE_COLLECTION.STATS,
    });

    healthKitData.value = data || [];
    currentPage.value = 1;
  } catch (error) {
    toast.error(error.message);
  }
}

onMounted(async () => {
  await updateHealthKitData();
});
</script>

<style lang="scss" scoped>
.statistic {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;

  @media screen and (min-width: 1366px) {
    grid-template-columns: 1fr 35%;
  }

  .statistic-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}
</style>
