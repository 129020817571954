<script setup>
import { computed, ref } from 'vue';
import SwitchInput from '@/components/SwitchInput.vue';
import Multiselect from '@vueform/multiselect';
import { HEALTH_KIT_DATA_TYPES, OPTIONS_FREQUENCY_STUDY } from '@/studies/utils';

const props = defineProps({
  studyData: Object,
});

const healthOptions = ref(HEALTH_KIT_DATA_TYPES);

const emits = defineEmits(['update:studyData']);

const studyForm = computed({
  set: (newValue) => emits('update:studyData', newValue),
  get: () => props.studyData,
});
</script>

<template>
  <div class="w-100 form-group">
    <SwitchInput
      v-model="studyForm.participantHealthData.shouldCollect"
      label="Collect health data"
      class="mt-0"
    />
  </div>

  <template v-if="studyForm.participantHealthData?.shouldCollect">
    <div class="w-100 form-group">
      <label for="studyDuration" class="text-muted">Select Health Data to collect</label>
      <multiselect
        placeholder="Energy, Heart Rate,..."
        class="dropdown"
        v-model="studyForm.participantHealthData.dataTypes"
        :options="healthOptions"
        :searchable="true"
        :required="true"
        mode="tags"
      />
    </div>

    <section class="w-100 form-group">
      <label for="studyDuration" class="text-muted">Select the frequency</label>
      <multiselect
        placeholder="Select frequency study"
        class="dropdown"
        label="label"
        value="value"
        v-model="studyForm.participantHealthData.frequencyInHours"
        :options="OPTIONS_FREQUENCY_STUDY"
        :searchable="true"
        :required="true"
      />
    </section>

    <div class="w-100 form-group">
      <label for="studyDuration" class="text-muted">Collect previous data (in Days)</label>
      <input
        class="form-input"
        type="number"
        placeholder="1"
        min="1"
        max="90"
        id="studyDuration"
        v-model="studyForm.participantHealthData.dataFromDaysAgo"
        required
      />
    </div>
  </template>
</template>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;

  .form-group {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;

    label {
      text-align: start;
      margin-bottom: 3px;
      font-size: 10pt;
    }

    a {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  button#b-form {
    margin-top: 1rem;
  }
}

.dropdown {
  margin-top: 2px;
  --ms-option-bg-pointed: #f3f8fb;
  --ms-option-bg-selected: #e5f1ff;
  --ms-option-color-selected: #052049;
  --ms-option-bg-selected-pointed: #e5f1ff;
  --ms-option-color-selected-pointed: #052049;
  --ms-ring-color: transparent;
  --ms-py: 4.5px;
  --ms-tag-font-size: 13.3px;
  --ms-tag-bg: #34455A;
}
</style>
