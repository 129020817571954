<template>
  <section>
    <button
      :disabled="isEdition"
      class="form-input w-100 addTask-button"
      type="button"
      @click="handleOpenModal"
      required
    >
      Add New Task <b>+</b>
    </button>
    <modal
      :title="`${isTaskEdition ? 'Edit' : 'Register'} Task`"
      :maxWidth="'450px'"
      :showModal="isTogglingModal"
      @closeModal="handleCloseModal"
    >
      <template v-slot:content>
        <form class="form" @submit.prevent="handleSubmitRegister">
          <div class="m-2 form-group">
            <label for="taskType" class="flex text-muted">Select Task Type</label>
            <select
              @change="handleTaskTypeChange"
              class="form-select w-100"
              id="taskType"
              v-model="taskData.taskType"
              required
            >
              <option value="" selected disabled hidden>Choose a task type</option>
              <option :value="TASKS_TYPES.SURVEY">Survey</option>
              <option :value="TASKS_TYPES.EXERCISE">Exercise</option>
            </select>
          </div>
          <div class="m-2 form-group" v-if="taskData.taskType === TASKS_TYPES.SURVEY">
            <label for="taskValue" class="flex text-muted">Select Survey</label>
            <select
              class="form-select w-100"
              id="taskValue"
              v-model="taskData.taskValue"
              :key="taskData.taskType"
              required
            >
              <option value="" selected disabled hidden>Choose one survey</option>
              <option v-for="survey in surveys" :value="survey.id">
                {{ survey.name }}
              </option>
            </select>
          </div>
          <div class="m-2 form-group" v-else-if="taskData.taskType === TASKS_TYPES.EXERCISE">
            <label for="taskValue" class="flex text-muted">Select Exercise</label>
            <select
              class="form-select w-100"
              id="taskValue"
              v-model="taskData.taskValue"
              :key="taskData.taskType"
              required
            >
              <option value="" selected disabled hidden>Choose one exercise</option>
              <option v-for="exercise in exercises" :value="exercise.id">
                {{ exercise.name }}
              </option>
            </select>
          </div>
          <div class="m-2 form-group">
            <label for="taskDelay" class="flex text-muted">Task Delay (in Days)</label>
            <input
              class="form-input w-100"
              type="number"
              id="taskDelay"
              min="0"
              max="100"
              v-model="taskData.taskDelay"
              required
            />
          </div>
          <div class="m-2 form-group">
            <label for="taskFrequency" class="flex text-muted">Task Frequency (in Days)</label>
            <input
              class="form-input w-100"
              type="number"
              id="taskFrequency"
              v-model="taskData.taskFrequency"
              required
            />
          </div>
          <div class="m-2 form-group">
            <label for="taskRepetitions" class="flex text-muted">Task Repetitions</label>
            <input
              class="form-input w-100"
              type="number"
              id="taskRepetitions"
              v-model="taskData.taskRepetitions"
              required
            />
          </div>
          <div class="m-2 form-group">  
            <label for="taskName" class="flex text-muted">Task Name</label>
            <input
              class="form-input w-100"
              type="text"
              placeholder="Task Name"
              id="taskName"
              v-model="taskData.taskName"
              required
            />
          </div>
          <div class="m-2 form-group">
            <label for="taskSeriesNumber" class="flex text-muted"
              >Series number (The repetition count for each exercise)</label
            >
            <input
              class="form-input w-100"
              type="number"
              placeholder="Series number"
              id="taskSeriesNumber"
              min="1"
              v-model="taskData.taskSeriesNumber"
              required
            />
          </div>
          <div class="m-2 form-group">
            <label for="taskOpenWindow" class="flex text-muted"
              >Task Open Window (in Days)</label
            >
            <input
              class="form-input w-100"
              type="number"
              placeholder="0"
              id="taskOpenWindow"
              min="1"
              v-model="taskData.taskOpenWindow"
              required
            />
          </div>
          <div class="flex gap-3 mt-5 w-100">
            <button class="m-auto btn btn-ck" type="submit">
              {{ isTaskEdition ? 'Save Changes' : 'Register Task' }}
            </button>
          </div>
        </form>
      </template>
    </modal>
  </section>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import Modal from '@/components/Modal';
import { toast } from 'vue3-toastify';
import { TASKS_TYPES } from '@/common/constants';
import { getSurveys, getExercises } from '@/studies/services/tasks';

const DIALOG_MESSAGES = {
  EDIT: 'The task has been edited successfully',
  ADD: 'The task has been added successfully',
};

const emit = defineEmits(['addTask', 'editTask', 'exitTaskEdition']);

const props = defineProps({
  isEdition: {
    type: Boolean,
    required: false,
    default: false,
  },
  isTaskEdition: {
    type: Boolean,
    required: false,
    default: false,
  },
  selectedTask: {
    type: Object,
    required: false,
    default: () => ({}),
  },
});

const defaultTaskInformation = {
  taskType: '',
  taskValue: '',
  taskDelay: 0,
  taskRepetitions: 1,
  taskFrequency: 1,
  taskName: '',
  taskSeriesNumber: 0,
  taskOpenWindow: 0,
};

const isTogglingModal = ref(false);
const taskData = ref({ ...defaultTaskInformation });
const surveys = ref([]);
const exercises = ref([]);

function handleOpenModal() {
  isTogglingModal.value = true;
}

function handleCloseModal() {
  isTogglingModal.value = false;
  taskData.value = { ...defaultTaskInformation };
  if (props.isTaskEdition) emit('exitTaskEdition');
}

function handleTaskTypeChange() {
  taskData.value.taskValue = '';
}

async function handleSubmitRegister() {
  try {
    if (props.isTaskEdition) {
      emit('editTask', { ...taskData.value, edited: true });
      toast.success(DIALOG_MESSAGES.EDIT);
      isTogglingModal.value = false;
    } else {
      emit('addTask', taskData.value);
      toast.success(DIALOG_MESSAGES.ADD);
      handleCloseModal();
    }
  } catch (error) {
    toast.error(error.message);
  }
}

onMounted(async () => {
  try {
    surveys.value = await getSurveys();
    exercises.value = await getExercises();
  } catch (error) {
    toast.error(error.message);
  }
});

watch(
  () => props.selectedTask,
  () => {
    taskData.value = props.selectedTask;
    isTogglingModal.value = true;
  }
);
</script>

<style lang="scss" scoped>
.wrapper-form {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  height: 100vh;
  background: $background-signup;

  .card-header {
    margin-bottom: 2rem;

    h1#title {
      font-size: 2rem;
    }

    span {
      font-size: 10pt;
    }

    @media screen and (max-width: 510px) {
      margin-bottom: 1rem;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .form-group {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;

      label {
        text-align: start;
        margin-bottom: 3px;
        font-size: 10pt;
      }

      a {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }

    button#b-form {
      margin-top: 1rem;
    }
  }
}

.addTask-button {
  &:not(:disabled):hover {
    background-color: lightgrey;
    border: 1px solid black;
  }
  &:disabled {
    cursor: default;
  }
}
</style>
