<template>
  <div>
    <apex-charts ref="chart" height="300" type="line" :options="chartOptions" :series="series" />
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from 'vue';
import ApexCharts from 'vue3-apexcharts';

const chart = ref(null);

const props = defineProps({
  series: {
    type: Array,
    required: true,
  },
  isTooltipCustom: {
    type: Boolean,
    required: false,
    default: false,
  },
  hkCode: {
    type: String,
    required: false,
  },
});

const zoomX = (fromDate, toDate) => {
  if (chart.value?.chart) {
    chart.value.zoomX(fromDate.getTime(), toDate.getTime());
  }
};

const chartOptions = computed(() => ({
  chart: {
    id: 'vuechart-line',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  xaxis: {
    type: 'datetime',
    labels: {
      datetimeFormatter: {
        year: 'yyyy',
        month: "MMM 'yy",
        day: 'MMM dd',
        hour: 'MMM dd/HH:mm',
      },
    },
  },
  stroke: {
    curve: 'smooth',
  },
  tooltip: {
    custom: props.isTooltipCustom
      ? function ({ series, seriesIndex, dataPointIndex, w }) {
          const data = w.config.series[seriesIndex].data[dataPointIndex];
          return `
            <div style="padding: 10px; border-radius: 5px; background: #fff; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);">
              <strong>${props.hkCode}</strong><br>
              <span>Start: ${data.startDate}</span><br>
              <span>End: ${data.endDate}</span><br>
              <span>Value: ${data.y}</span>
            </div>`;
        }
      : undefined,
  },
}));
</script>
