export const FREQUENCY_STUDY = {
  EVERY_HOUR: 1,
  EVERY_3_HOURS: 3,
  EVERY_8_HOURS: 8,
  EVERY_24_HOURS: 24,
  WEEKLY: 168,
  MONTHLY: 720,
  EVERY_3_MONTHS: 2160,
};

export const OPTIONS_FREQUENCY_STUDY = [
  { label: 'Every hour', value: FREQUENCY_STUDY.EVERY_HOUR },
  { label: 'Every 3 hours', value: FREQUENCY_STUDY.EVERY_3_HOURS },
  { label: 'Every 8 hours', value: FREQUENCY_STUDY.EVERY_8_HOURS },
  { label: 'Every 24 hours', value: FREQUENCY_STUDY.EVERY_24_HOURS },
  { label: 'Weekly', value: FREQUENCY_STUDY.WEEKLY },
  { label: 'Monthly', value: FREQUENCY_STUDY.MONTHLY },
  { label: 'Every 3 months', value: FREQUENCY_STUDY.EVERY_3_MONTHS },
];

export const HK_SLEEP_ANALYSIS = 'HKSleepAnalysis';

export const HEALTH_KIT_DATA_TYPES = [
  { label: 'Walking Speed', value: 'HKQuantityTypeIdentifierWalkingSpeed', isActive: true },
  {
    label: 'Distance Walking/Running',
    value: 'HKQuantityTypeIdentifierDistanceWalkingRunning',
    isActive: true,
  },
  {
    label: 'Walking Double Support Percentage',
    value: 'HKQuantityTypeIdentifierWalkingDoubleSupportPercentage',
    isActive: true,
  },
  {
    label: 'Walking Step Length',
    value: 'HKQuantityTypeIdentifierWalkingStepLength',
    isActive: true,
  },
  {
    label: 'Walking Heart Rate Average',
    value: 'HKQuantityTypeIdentifierWalkingHeartRateAverage',
    isActive: true,
  },
  {
    label: 'Walking Asymmetry Percentage',
    value: 'HKQuantityTypeIdentifierWalkingAsymmetryPercentage',
    isActive: true,
  },
  { label: 'Apple Stand Time', value: 'HKQuantityTypeIdentifierAppleStandTime', isActive: true },
  { label: 'Step Count', value: 'HKQuantityTypeIdentifierStepCount', isActive: true },
  {
    label: 'Heart Rate Variability SDNN',
    value: 'HKQuantityTypeIdentifierHeartRateVariabilitySDNN',
    isActive: true,
  },
  { label: 'Heart Rate', value: 'HKQuantityTypeIdentifierHeartRate', isActive: true },
  {
    label: 'Resting Heart Rate',
    value: 'HKQuantityTypeIdentifierRestingHeartRate',
    isActive: true,
  },
  {
    label: 'Apple Exercise Time',
    value: 'HKQuantityTypeIdentifierAppleExerciseTime',
    isActive: true,
  },
  {
    label: 'Sleep Analysis',
    value: HK_SLEEP_ANALYSIS,
    isActive: true,
  },
  {
    label: 'Apple Walking Steadiness',
    value: 'HKQuantityTypeIdentifierAppleWalkingSteadiness',
    isActive: true,
  },
];
