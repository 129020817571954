<template>
  <section v-if="isModalOpen">
    <div class="modal">
      <div class="modal-content" :style="{ maxWidth }">
        <a class="modal-hide" :class="withoutCloseOption ? 'display-none' : 'display-initial'" @click="$emit('closeModal')">✕</a>
        <h2 class="m-4">{{ title }}</h2>
        <section class="m-4">
          <slot name="content"></slot>
        </section>
        <section class="m-5">
          <slot name="actions"></slot>
        </section>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps(["title", "content", "showModal", "maxWidth", "withoutCloseOption"])

const isModalOpen = ref(props.showModal);

watch(() => props.showModal, (value) => {
    isModalOpen.value = value;
});
</script>

<style  lang="scss" scoped>
.inline {
  display: inline-flex;
}

.modal {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  animation: modal-show 0.5s 1 forwards;
}

.modal-content {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  background-color: white;
  text-align: center;
  max-height: 95vh;
  overflow-y: auto;
}

.modal:target {
  display: flex;
  z-index: 100;
}

.modal-hide {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 30px;
  height: 30px;
  padding: 0;
  background-color: transparent;
  border: none;
  font-size: 18px;
  text-align: center;
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.display-none {
  display: none;
}

.display-initial {
  display: initial;
}

@keyframes modal-show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>