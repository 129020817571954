<template>
  <div>
    <apex-chart
      v-if="isSeriesValid"
      height="300"
      type="bar"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import ApexChart from 'vue3-apexcharts';

const props = defineProps({
  series: {
    type: Array,
    required: true,
  },
});

const isSeriesValid = computed(() => {
  return (
    Array.isArray(props.series) &&
    props.series.length > 0 &&
    props.series[0].data &&
    props.series[0].data.length > 0
  );
});

const chartOptions = computed(() => ({
  chart: {
    id: 'vuechart-example',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  xaxis: {
    labels: {
      datetimeFormatter: {
        year: 'yyyy',
        month: "MMM 'yy",
        day: 'MMM dd',
        hour: 'MMM dd/HH:mm',
      },
    },
  },
}));
</script>
